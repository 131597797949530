import {MenuItem, TextField} from "@mui/material";
import {useField, useFormikContext} from "formik";
import {connect} from "react-redux";

const SelectFieldLinkedinOrganizationsWrapper = (props) => {
    const {name, options, labelValue, idValue, simple, ...otherProps} = props;
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);


    const handleChange = (e) => {
        const {value} = e.target;
        console.log(value);
        setFieldValue(name, value);
        if (otherProps.updateState) {
            otherProps.updateState(value);
        }
    };

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange
    }

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    const fetchLinkedinOrganizations = () => {
        const linkedinCredentials = props.user.analytics_linkedin_integrations &&
        props.user.analytics_linkedin_integrations.length > 0
            ? props.user.analytics_linkedin_integrations[0]
            : { userSub: null, accessToken: null, accessTokenExpiration: null, organizationList: [] };

        if (!linkedinCredentials || !linkedinCredentials.userSub || !linkedinCredentials.accessToken ) {
            console.log("No LinkedIn credentials found");
            return;
        }
        console.log("LinkCred: ", linkedinCredentials)
        // console.log("Organizations: ", organizationsList)
        /*if (!defaultUserSub) {
            setDefaultUserSub("person:" + linkedinCredentials.userSub);
            setSelectedUserSub("person:" + linkedinCredentials.userSub);
            setAccessToken(linkedinCredentials.accessToken);
        }*/

        let data = linkedinCredentials.organizationList;
        const formattedData = data.replace(/'/g, '"');
        data = JSON.parse(formattedData);
        if (data && data.length > 0) {
            const filteredData = data.map(item => ({
                id: "organization:" + item.id,
                name: item.name,
                urn: item.urn
            }));

            return [{
                id: "person:" + linkedinCredentials.userSub,
                name: "Personal",
                urn: ""},
                ...filteredData];
            // setSelectedUserSub(filteredData[0]?.id || defaultUserSub);
        } else {
            console.log('No linkedin organizations available');
            return [{
                id: "person:" + linkedinCredentials.userSub,
                name: "Personal",
                urn: ""}];
        }
    };

    return (
        <TextField {...configSelect}>
            {fetchLinkedinOrganizations()?.map((item, pos) => {
                return (
                    <MenuItem key={item.id} value={item.id}>
                        { item.name}
                    </MenuItem>
                )
            })}
        </TextField>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectFieldLinkedinOrganizationsWrapper);
