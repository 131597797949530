import { connect } from "react-redux";
import { Badge, Chip, CircularProgress, Tooltip } from "@mui/material";
import logo from '../../assets/Linkedin_blue_logo.png';
import { toastr } from "react-redux-toastr";
import { useEffect } from "react";
import { setNewLinkedinIntegrationService, disconnectLinkedinIntegrationService } from "../../services/agent-post-ai.service";
import {
    deleteDriveUserThunk,
    getUserWithEmailThunk,
    updateDriveUserThunk,
    updateIndexUserThunk
} from "../../redux/users/users.thunk";
import {useState} from "react";

const CustomChip = (props) => {
    return (
        <Chip
            variant="filled"
            disabled={props.status === 'loading'}
            avatar={
                props.status === 'loading' ? (
                    <CircularProgress size={20} />
                ) : (
                    <img style={{ width: '35px', height: '35px' }} src={logo} alt="LinkedIn Logo" />
                )
            }
            label="LINKEDIN"
            {...props}
        />
    );
}

const LinkedinButton = (props) => {
    const [status, setStatus] = useState("initial")

    const existLinkedinAccount = () => {
        return props.user.analytics_linkedin_integrations !== null && props.user.analytics_linkedin_integrations?.length > 0;
    }

    const disconnect = async () => {
        if (window.confirm(`Are you sure you want to disconnect your Linkedin account ${props.user.analytics_linkedin_integrations[0]['vanityName']}?`)) {
            setStatus("loading")
            await disconnectLinkedinIntegrationService(props.user.id);
            await props.getUser(props.user.email);
            setStatus("loaded")
        }
    }

    const handleButtonClick = () => {
        const popup = window.open(`${process.env.REACT_APP_BACK_AI_POST}/login`, 'popup', 'width=600,height=600');
        console.log("Popup opened");

        const handleMessage = async (event) => {
            console.log("Received event:", event);

            const { accessToken, accessTokenExpiration, refreshToken, refreshTokenExpiration, sub, vanityName, organizationList} = event.data;
            console.log("Received data:", accessToken, "\nTOken Expiration: ", accessTokenExpiration, "\nRefresh Token: ",refreshToken,"\nRefresh Token Expiration: ", refreshTokenExpiration,"\nSUB: ", sub,"\nVanityName: ", vanityName,"\nList Org: ", organizationList);

            try {
                const response = await setNewLinkedinIntegrationService({
                    accessToken: accessToken,
                    accessTokenExpiration: accessTokenExpiration,
                    refreshToken: refreshToken,
                    refreshTokenExpiration: refreshTokenExpiration,
                    userSub: sub,
                    vanityName: vanityName,
                    organizationList: organizationList,
                    analyticsUserId: props.user.id 
                });
                console.log("RESPONSE INTEGRATION: ", accessToken,accessTokenExpiration, refreshToken, refreshTokenExpiration, sub, vanityName, organizationList, props.user.id );
                console.log("RESPONSE INTEGRATION: ", response);
                props.getUser(props.user.email);

                toastr.success("Connected!", "You have successfully logged in to your LinkedIn account");
                alert("LinkedIn integration successful");
            } catch (error) {
                console.error("Failed to set LinkedIn integration:", error);
                toastr.error("Failed to set LinkedIn integration");
                alert("Failed to set LinkedIn integration");
            } finally {
                window.removeEventListener('message', handleMessage);
                if (popup) {
                    popup.close();
                }
            }
        };

        window.addEventListener('message', handleMessage);
    };

    return (
        <Tooltip
            title={existLinkedinAccount() ? props.user.analytics_linkedin_integrations[0]["vanityName"] : ''}
            className="w-100"
        >
            <Badge color="success" variant="dot" invisible={!existLinkedinAccount()}>
                {existLinkedinAccount() ? (
                    <CustomChip
                        label={<small className="text-black-50">Connected</small>}
                        className="py-4 w-100 justify-content-center"
                        onClick={() => {}}
                        onDelete={() => disconnect()}
                        status={status}
                    />
                ) : (
                    <CustomChip
                        className="py-4 w-100"
                        onClick={handleButtonClick}
                        status={status}
                    />
                )}
            </Badge>
        </Tooltip>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        status: state.users.status,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedinButton);
